import { Typography } from '@mui/material';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { pluralDepartures } from '@src/departures/utils';
import { passengerCostTableHeaders } from './PassengerCostTable';
import MinimalTable, { MinimalTableData } from '@src/shared/table/MinimalTable';
import { flashPackTheme } from '@src/design-system/theme';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { ApolloError, useMutation } from '@apollo/client';
import {
  BulkUpdatePassengerCostsDocument,
  DepartureFinancesQueryDocument,
  ItineraryWithPassengerCostsDocument,
  PassengerCostAmountsInput,
  Scalars,
  SupportedCurrencySymbol,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { currencyOptions } from './CurrencyDropdown';
import usePermissions from '@src/shared/usePermissions';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  selectedDepartureIds: Array<Scalars['UUID']>;
  setSelectedDepartureIds?: Dispatch<SetStateAction<string[]>>;
  initialValues?: PassengerCostAmountsInput | null;
  costCurrency?: SupportedCurrencySymbol | null;
  locked?: boolean;
}

export const BulkUpdatePassengerCostsModal: FC<PropTypes> = ({
  open,
  onClose,
  selectedDepartureIds,
  setSelectedDepartureIds,
  initialValues,
  costCurrency,
  locked = false,
  ...props
}) => {
  const { safeMutation } = useSafeMutation();
  const { canUserSubmitForm } = usePermissions();
  const [bulkUpdatePassengerCosts] = useMutation(BulkUpdatePassengerCostsDocument);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const { error: errorToast, success: successToast } = useToast();

  const title = `Update ${pluralDepartures(selectedDepartureIds.length)}?`;
  const lockForm = !canUserSubmitForm(locked);

  const closeDialog = () => {
    setFormError(undefined);
    onClose();
  };

  const handleSubmitPassengerCosts = async (values: MinimalTableData) => {
    if (Object.values(values.rows[0].values).every((v) => !v)) {
      setFormError('At least one field needs to be filled');
      return;
    }

    setIsSubmitting(true);
    await safeMutation(
      bulkUpdatePassengerCosts({
        variables: {
          input: {
            departureIds: selectedDepartureIds,
            amounts: { ...values.rows[0].values } as PassengerCostAmountsInput,
          },
        },
        refetchQueries: [
          ItineraryWithPassengerCostsDocument,
          DepartureFinancesQueryDocument,
        ],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          closeDialog();
          successToast('Trip costs updated successfully');
          setSelectedDepartureIds && setSelectedDepartureIds([]);
        },
        onError: (err) => {
          setIsSubmitting(false);
          if (err instanceof ApolloError) {
            errorToast(err.message);
          } else {
            errorToast('Could not update passenger costs');
          }
        },
      },
    );
  };

  const initialData: MinimalTableData = {
    headers: passengerCostTableHeaders,
    rows: [
      {
        values: {
          amount4Pax:
            initialValues?.amount4Pax !== undefined ? initialValues?.amount4Pax : null,
          amount5Pax:
            initialValues?.amount5Pax !== undefined ? initialValues?.amount5Pax : null,
          amount6Pax:
            initialValues?.amount6Pax !== undefined ? initialValues?.amount6Pax : null,
          amount7Pax:
            initialValues?.amount7Pax !== undefined ? initialValues?.amount7Pax : null,
          amount8Pax:
            initialValues?.amount8Pax !== undefined ? initialValues?.amount8Pax : null,
          amount9Pax:
            initialValues?.amount9Pax !== undefined ? initialValues?.amount9Pax : null,
          amount10Pax:
            initialValues?.amount10Pax !== undefined ? initialValues?.amount10Pax : null,
          amount11Pax:
            initialValues?.amount11Pax !== undefined ? initialValues.amount11Pax : null,
          amount12Pax:
            initialValues?.amount12Pax !== undefined ? initialValues?.amount12Pax : null,
          amount13Pax:
            initialValues?.amount13Pax !== undefined ? initialValues?.amount13Pax : null,
          amount14Pax:
            initialValues?.amount14Pax !== undefined ? initialValues?.amount14Pax : null,
          amount15Pax:
            initialValues?.amount15Pax !== undefined ? initialValues?.amount15Pax : null,
          amount16Pax:
            initialValues?.amount16Pax !== undefined ? initialValues?.amount16Pax : null,
          amount17Pax:
            initialValues?.amount17Pax !== undefined ? initialValues?.amount17Pax : null,
          amount18Pax:
            initialValues?.amount18Pax !== undefined ? initialValues?.amount18Pax : null,
        },
      },
    ],
  };

  const currencyDisplayed = currencyOptions.find(
    (option) => option.value === costCurrency,
  )?.label;

  return (
    <ConfirmationDialog
      {...props}
      open={open}
      onCancel={closeDialog}
      title={title}
      maxWidth="md"
      cancelLabel="Cancel"
      confirmLabel="Update"
      confirmFormId="bulk-update-passenger-costs-table"
      confirmLoading={isSubmitting}
      disableConfirmButton={lockForm}
    >
      <Typography variant="captionPara" color={flashPackTheme.palette.principal.grey70}>
        Passenger Costs are {currencyDisplayed ? currencyDisplayed : '...'}
      </Typography>

      <MinimalTable
        initialData={initialData}
        handleSubmit={handleSubmitPassengerCosts}
        tableFormId="bulk-update-passenger-costs-table"
        required={false}
        tooltipText="Enter a new cost to update all selected instances"
        formError={formError}
        editable={!lockForm}
      />
    </ConfirmationDialog>
  );
};
